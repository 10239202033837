
import { defineComponent, ref, toRefs, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import SolanaServices from "@/core/services/SolanaServices";
import { showModal } from "@/core/helpers/dom";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import axios from "axios";


export default defineComponent({
  name: "VehicleDetail",
  props: {},
  components: {
  },
  emits: [],
  setup(props, { emit }) {
    const confirmarModalRef = ref<any>();
    const devolverModalRef = ref<any>();
    const route = useRoute();
    const dataVehicle = ref<any>({});
    const titulo = ref<any>();
    const portada = ref<string>("");
    const nombreAuto = ref<string>("");
    const autoInteres = ref<string>("");
    const autoInteresKm = ref<string>("");
    const precio = ref<any>();
    const idVehicle = ref<number>(0);
    const vin = ref<string>("");
    const anio = ref<string>("");
    const brand = ref<string>("");
    const model = ref<string>("");
    const version = ref<string>("");
    const tipo = ref<string>("");
    const transmision = ref<string>("");
    const color = ref<string>("");
    const kilometraje = ref<string>("");
    const status = ref<string>("");
    const tituloFecha = ref<string>("");
    const idAuto = ref<any>();
    const userRole = ref<any>();
    const tipoRol = ref<string>("Agencia");
    //Cliente
    const cliente_nombre = ref<string>("");
    const cliente_correo = ref<string>("");
    const cliente_telefono = ref<string>("");
    //Agencia
    const agencia_nombre = ref<string>("");
    const agencia_correo = ref<string>("");
    const agencia_telefono = ref<string>("");
    const agencia_id = ref<string>("");
    //Transaccion
    const tra_cantidad = ref<any>();
    const tra_auth = ref<string>("");
    const tra_fecha = ref<string>("");
    const tid = ref<string>("");
    //Tarjeta
    const tar_tipo = ref<string>("");
    const tar_marca = ref<string>("");
    const tar_nombre = ref<string>("");
    const tar_banco = ref<string>("");
    const tar_fecha = ref<string>("");
    const tar_tarjeta = ref<string>("");
    const fecha_apartado = ref<string>("");


    const dropShow = ref<boolean>(false);

    const getDetail = async () => {
      const {data} = await ApiService.get("api/vehicle/apartado/"+route.params.uuid);
      console.log(data.data.a_cuenta);
      if(data.data.a_cuenta != undefined){
        autoInteres.value = data.data.a_cuenta.vehiculo;
        autoInteresKm.value = formatPrice(data.data.a_cuenta.kms);
        if(autoInteresKm.value.includes('.')){
          autoInteresKm.value = autoInteresKm.value.split('.')[0];
        }
      }
      let transaction = data.data.transaction;
      let vehicle = data.data.vehicle;
      let cliente = data.data.client;
      let tarjeta = data.data.card;
      let agencia = data.data.agency;
      tid.value = transaction.tid;
      status.value = data.data.status.status;
      if(status.value == 'Confirmado') tituloFecha.value = 'Fecha de confirmación';
      if(status.value == 'Devuelto') tituloFecha.value = 'Fecha de devolución';
      if(status.value == 'Retenido') tituloFecha.value = 'Fecha de cargo o devolución';
      nombreAuto.value = vehicle.brand + " " + vehicle.model;
      precio.value = formatPrice(vehicle.price);
      titulo.value = "  Apartados - " + cliente.name + " - ";
      idVehicle.value = vehicle.id;
      await getVehicleData(vehicle.id);
      vin.value = vehicle.vin.toUpperCase();
      anio.value = vehicle.year;
      brand.value = vehicle.brand;
      model.value = vehicle.model;
      version.value = vehicle.version;
      tipo.value = vehicle.type;
      transmision.value = vehicle.transmission;
      color.value = vehicle.color;
      kilometraje.value = formatPrice(vehicle.mileage);
      if(kilometraje.value.includes('0')){
        kilometraje.value = kilometraje.value.split('.')[0];
      }
      cliente_nombre.value = cliente.name;
      cliente_correo.value = cliente.email;
      cliente_telefono.value = cliente.phone;
      agencia_nombre.value = agencia.name;
      agencia_correo.value = agencia.email;
      agencia_telefono.value = agencia.phone;
      tra_cantidad.value = formatPrice(transaction.cant);
      tra_auth.value = transaction.authorize;
      if(transaction.confirmdate != '' && transaction.confirmdate != null){
        let formatConfirmDate = transaction.confirmdate.split(' ')[0];
        let formatFechaConfirm = formatConfirmDate.split('-');
        tra_fecha.value = formatFechaConfirm[2] + '/' + formatFechaConfirm[1] + '/' + formatFechaConfirm[0];
      }
      if(tarjeta.type == 'debit'){
        tar_tipo.value = 'Débito';
      }
      if(tarjeta.type == 'credit'){
        tar_tipo.value = 'Crédito';
      }
      tar_marca.value = tarjeta.brand;
      tar_nombre.value = tarjeta.name;
      tar_banco.value = tarjeta.bank;
      tar_fecha.value = SolanaServices.getDateFormatString(tarjeta.operationdate);
      tar_tarjeta.value = tarjeta.num;
      portada.value = vehicle.cover;
      const fechaFormat = tarjeta.operationdate.split(' ');
      const fechaOnly = fechaFormat[0].split('-');
      fecha_apartado.value = fechaOnly[2]+'/'+fechaOnly[1]+'/'+fechaOnly[0];
    }

    const confirmar = async () => {
      mostrarDrop();
      showModal(confirmarModalRef.value);
    }

    const descartarConfirmado = async () => {
      hideModal(confirmarModalRef.value);
    }

    const devolver = async () => {
      mostrarDrop();
      showModal(devolverModalRef.value);
    }

    const descartarDevuelto = async () => {
      hideModal(devolverModalRef.value);
    }

    const sendConfirmar = async () => {
      const dataToConfirmar = {
        tid: tid.value,
      };
      descartarConfirmado();
      await axios.post("api/apartados/op/confirm/"+agencia_id.value+"/"+route.params.uuid, dataToConfirmar)
      .then((response) => {
        if(response.data.data.confirmdate != '' && response.data.data.confirmdate != null){
          let fecha = response.data.data.confirmdate;
          let formatConfirmDate = fecha.split(' ')[0];
          let formatFechaConfirm = formatConfirmDate.split('-');
          tra_fecha.value = formatFechaConfirm[2] + '/' + formatFechaConfirm[1] + '/' + formatFechaConfirm[0];
          tituloFecha.value = 'Fecha de confirmación';
        }
        status.value = 'Confirmado';
      }, (error) => {
        Swal.fire({
            position: "center",
            icon: "error",
            html: "Ocurrió un error al confirmar apartado",
            showConfirmButton: true,
        });
      });
    }

    const sendDevolver = async () => {
      const dataToDevolver = {
        tid: tid.value,
      };
      descartarDevuelto();
      await axios.post("api/apartados/op/refund/"+agencia_id.value+"/"+route.params.uuid, dataToDevolver)
      .then((response) => {
        console.log(response);
        if(response.data.data.confirmdate != '' && response.data.data.confirmdate != null){
          let fecha = response.data.data.confirmdate;
          let formatConfirmDate = fecha.split(' ')[0];
          let formatFechaConfirm = formatConfirmDate.split('-');
          tra_fecha.value = formatFechaConfirm[2] + '/' + formatFechaConfirm[1] + '/' + formatFechaConfirm[0];
          tituloFecha.value = 'Fecha de devolución';
        }
        status.value = 'Devuelto';
      }, (error) => {
        Swal.fire({
            position: "center",
            icon: "error",
            html: "Ocurrió un error al devolver apartado",
            showConfirmButton: true,
        });
      });
    }

    const getVehicleData = async (idVehicle) => {
      const { data } = await ApiService.get("/api/vehicle/" + idVehicle);
      agencia_id.value = data.data.agency.id;
    }

    onMounted(async () => {
      const {data} = await SolanaServices.getTipoRol();
      tipoRol.value = data.data[0].type;
      await getDetail();
    });

    const mostrarDrop = () => {
        dropShow.value = !dropShow.value;
    }

    const formatPrice = (value) => {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return {
      dataVehicle,
      formatPrice,
      titulo,
      portada,
      nombreAuto,
      precio,
      vin,
      anio,
      brand,
      model,
      version,
      tipo,
      transmision,
      color,
      kilometraje,
      status,
      idAuto,
      userRole,
      tipoRol,
      getDetail,
      cliente_nombre,
      cliente_correo,
      cliente_telefono,
      agencia_nombre,
      agencia_correo,
      agencia_telefono,
      tra_cantidad,
      tra_auth,
      tra_fecha,
      tar_tipo,
      tar_marca,
      tar_nombre,
      tar_banco,
      tar_fecha,
      tar_tarjeta,
      idVehicle,
      fecha_apartado,
      dropShow,
      mostrarDrop,
      confirmarModalRef,
      confirmar,
      descartarConfirmado,
      devolverModalRef,
      devolver,
      descartarDevuelto,
      sendConfirmar,
      sendDevolver,
      agencia_id,
      getVehicleData,
      tid,
      tituloFecha,
      autoInteres,
      autoInteresKm
    };
  },
});
